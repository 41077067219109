import React from 'react'
import PropTypes from 'prop-types'

import pic02 from '../images/M04_RiihimakiHohenberg2015.jpg'
import pic03 from '../images/67625231_279636212907784_8503057471005261824_n.jpg'
import pic01 from '../images/67872057_2337675113227631_7962588381433561088_n.jpg'

import arte from '../images/logos/arte.gif'
import edel from '../images/logos/edel.gif'
import khb from '../images/logos/khb.png'
import sonyclassical from '../images/logos/sonyclassical.png'
import ssd from '../images/logos/ssd.png'
import zdf from '../images/logos/zdf.png'

class Main extends React.Component {
  state = {
    name: '',
    email: '',
    msg: '',
    formOffline: true,
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }
  handleInputReset = event => {
    event.preventDefault()
    this.setState({
      name: '',
      email: '',
      msg: '',
    })
  }
  handleSubmit = event => {
    event.preventDefault()
    alert(`Welcome ${this.state.name} ${this.state.email} ${this.state.msg}!`)
  }
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="news"
          className={`${this.props.article === 'news' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">News</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>

          <h3 className="lift">15.06.2021</h3>
          <p className="lift">I am very happy to be preparing the concerts with the wonderful Simone Kermes at the Paliesiaus Dvaras festival this weekend. <a href="http://www.paliesiusmanor.com/" target="_blank" rel="noopener noreferrer">http://www.paliesiusmanor.com/</a><br /><br />
          Here a snippet of my Lili Marleen arrangement, hope you like it:<br /><a href="https://fb.watch/68krsqkCg1/" target="_blank" rel="noopener noreferrer">https://fb.watch/68krsqkCg1/</a></p>

          <h3>06.06.2021</h3>
          <p>I’m extremely excited to announce, that the first single of Emily D’Angelo’s ”Enargeia” is out! I was widely involved with the project, both arranging/adapting some songs and conducting the orchestra. Working with Emily was just very inspiring, this is not your typical debut album of a young opera singer. Here the first teaser ”O frondens virga” (Hildegard of Bingen) as a very cool arrangement for solo cello and electronics by Missy Mazzoli:<br /><br /><a href="https://www.deutschegrammophon.com/de/katalog/produkte/enargeia-emily-dangelo-12353" target="_blank" rel="noopener noreferrer">https://www.deutschegrammophon.com/de/katalog/produkte/enargeia-emily-dangelo-12353</a><br /><br />
          More info with complete credits and thanks to come in October, when the album comes out. I am naturally biased, but it will be great!<br /><br />
          Many congratulations Emily for the first single. And already many thanks to my partner in crime in this project, the great sound engineer and producer Jonas Niederstadt!</p>

          <h3>19.03.2021</h3>
          <p>On Saturday, March the 20th, I have a joy to play a stream concert with the fantastic violinist Pekka Kuusisto. The concert is a part of the <a href="https://www.arte.tv/en/videos/RC-020686/europe-home/" target="_blank" rel="noopener noreferrer">"Europe@Home"</a>, a series hosted and organized by the violinist Daniel Hope together with the ARTE tv channel. He has done an immense work, and the concerts actually take place in his living room in Berlin.<br /><br /><a href="https://www.arte.tv/de/videos/102131-017-A/europe-home-finnland/">Tune in on Saturday at 7pmCET (20:00 in Finland)!</a>
          </p>
          <h3>23.01.2021</h3>
          <p>The first single of the upcoming album of the Finnish artist Yona out now! I wrote the orchestral arrangement, Tapiola Sinfonietta was conducted by Taavi Oramo.<br /><br />
            "IRTI"<br />
            Music: Yona &amp; Mauri Syrjälä<br />
            Lyrics: Yona<br />
            <a href="https://open.spotify.com/track/0imG6B15QuPms66eyiggCG?si=09ew1wGNTFS-RiJ-U7cG-Q" target="_blank" rel="noopener noreferrer">Listen in Spotify</a>
          </p>
          <h3>07.09.2020</h3>
          <p>
            I am happy to announce that I will be playing a stream concert with
            the luminous soprano Simone Kermes on Sunday, the 13th of September!
            The program is a mixture of arias and art songs, also some
            arrangements and originals of mine are included. More info and
            tickets here:{' '}
            <a
              href="https://dreamstage.live/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://dreamstage.live/
            </a>
          </p>
          <h3>06.06.2020</h3>
          <p>
            The new album of the marvellous violinist Lisa Batiashvili was
            released yesterday. It is called “City Lights”, and it is a
            colourful collection of music that have a connection to places that
            are important for Lisa. For Helsinki we chose a Finnish folk tune,
            “Evening Song”, that I arranged for her and for the RSB Berlin. I am
            deeply moved by her soulful performance, you can listen to it{' '}
            <a
              href="https://open.spotify.com/track/6hayyVRquyjHTD6MzAOlnB?si=00Iltw1SQy2gem1cjGig5Q"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </p>

          <h3>27.05.2020</h3>
          <p>
            A new video out!!! I played a new tune of mine with great
            colleagues, check it out! <br />
            <br />
            <iframe
              width="100%"
              height="300"
              src="https://www.youtube.com/embed/qw0QVJPPv70"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </p>

          <h3>21.10.2019</h3>
          <p>
            Preparing for the premiere of ”The Music Wizards” with maestro Veli
            Kujala, more info to follow! I keep working on the double bass
            sonata for Ander Perrino and me as well as working on arrangements
            for others. A new one will be heard on the Christmas tour of the
            great a cappella - group{' '}
            <a
              href="https://clubforfive.fi/en/frontpage-2/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Club For Five
            </a>
            . I have also organized more time for practicing the piano, which
            gives me a great pleasure!
          </p>

          <h3>Dates</h3>
          <ul>
            <li>
              <a
                href="https://www.teatrofernangomez.es/actividades/avance-de-temporada-20192020"
                target="_blank"
                rel="noopener noreferrer"
              >
                22.11. - 15.12.2019 El niño y la bestia - Madrid, Spain
              </a>
            </li>
            <li>
              <a
                href="https://muth.at/en/events/das-beste-zum-schluss-2019-20191231/"
                target="_blank"
                rel="noopener noreferrer"
              >
                31.12.2019 ”Schorny & Friends” - Vienna, Austria
              </a>
            </li>
            <li>
              <a
                href="https://www.talviharmonikka.com/?lang=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                12.02.2020 ”The Music Wizards” with Veli Kujala - Kokkola,
                Finland{' '}
              </a>
            </li>
            <li>
              <a
                href="https://ticketgretchen.com/events/tirol/innsbruck/haus-der-musik-innsbruck-grosser-saal/benjamin-schmid-bachreflected-3854/"
                target="_blank"
                rel="noopener noreferrer"
              >
                11.06.2020 ”Bach : Reflected” with Benjamin Schmid & Christian
                Wendt - Innsbruck, Austria
              </a>
            </li>
          </ul>
          {close}
        </article>

        <article
          id="bio"
          className={`${this.props.article === 'bio' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Bio</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <p>
            Duke Ellington allegedly once stated: "There are simply two kinds of
            music: good music and the other kind." Jarkko Riihimäki has always
            been passionate about many kinds of music.The son of two choir
            conductors, he grew up in a musical household.
          </p>
          <p>
            Growing up, he spent a lot of time wherever his parents were
            working. Whether in music schools, choir rehearsals, or churches;
            you name it, Jarkko was there soaking it all in. ”Of course it was
            all about playing with other kids”, he says, “but I guess something
            of that music in the background must have stayed. I remember that
            certain pieces always made me emotional. Also the sound of the organ
            gave me chills — sitting next to my father playing it was the
            coolest thing. Made me kind of proud as well."
          </p>
          <p>
            Jarkko started learning instruments at an early age. He started
            cello when he was 5 and piano a year later. "Many of even my closest
            friends now don't know that I played the cello. And for 11 years! I
            guess I was a very bad student; playing around with the
            neighbourhood kids was much more important than practicing. But the
            absolute highlight was playing Dvorak's 9th symphony in a youth
            orchestra. For me, having played the cello is pretty much the core
            for becoming a music arranger, especially when writing for the
            orchestra. So despite the lack of motivation from time to time, I'm
            very thankful for those years; they gave me a lot."
          </p>
          <p>
            Playing piano was more of a natural thing for him. "Already as a
            child I would spend hours at the piano, just trying to learn things
            I had heard. A pop song, something we sang in the school, or even
            the tunes from tv shows. That might sound like a joke, but trying to
            learn those TV theme songs was actually not unlike arranging. For
            some kids I might have been a little weird, going around with a Sony
            Walkman on and listening to Mozart. But then again, an hour later it
            was Wham or Michael Jackson. It was only later that I started seeing
            a connection between different styles. Going from pop music to
            listening to jazz was a natural path. And the more I learned the
            harmonic progressions of jazz, the better I began to understand
            harmonic structures in orchestral music."
          </p>
          <p>
            While studying at the renowned Sibelius Academy in Helsinki,
            Jarkko’s main interest were in the area of German and French art
            song (Lied and mélodie). "I took all the classes I could and
            participated in numerous masterclasses. I also accompanied my fellow
            students' lessons, which was really the best way to gather
            repertoire." Later this experience would become essential when
            working at the College of Music Berlin (UDK). Also playing with
            singers feels very natural for him. "As a pianist one has to
            understand how a singer breathes, and softly manipulate the music
            towards the end of a phrase so that it sounds organic and natural.
            Breathing together is, of course, the key to any kind of music."
          </p>
          <p>
            An open musical mind led Jarkko to play and study many kinds of
            music. A workshop of the late Horace Boyer in Connecticut was a
            mind-blowing experience for him at age sixteen. "That definitely
            gave me a kick to learn about African-American musical traditions.
            He would even let me play, I still remember that the song was It's
            my desire, an original of his. Wow... there was something about this
            music that gave me the feeling as if I had known it for a very long
            time." Only a couple years later he became the pianist of the
            Helsinki - based choir "His Master’s Noise”, a job he kept for the
            next six years.
          </p>
          <p>
            Through these gospel gigs he also got to play with great studio
            musicians, who taught him the philosophy of band playing. "In a
            nutshell, it's actually very simple: just play less. The band has to
            sound great, not you.” This proved a valuable lesson for him, later
            on, playing with bands, whether in a studio or on the stage. Working
            on the groove is not only a never-ending process but a source of
            satisfaction and excitement. “I could listen hours to a good groove.
            The first minutes of the bass player Pino Palladino and the drummer
            Chris "Daddy" Dave in a D'Angelo concert made me cry, it had an
            unexplainable depth in it."
          </p>
          <p>
            As an arranger, Riihimäki feels most at home when working with
            different musical worlds simultaneously. ”Let's say there is a
            production with a pop artist, and s/he sings with a classical
            orchestra. I am familiar with the pop aesthetics, but I also know
            how a classically trained musician reads the music. So it's about
            making the group of musicians understand each other. When writing
            for an orchestra I feel like a kid in a candy store, there are so
            many possibilities!"
          </p>
          <p>
            Of other forms of art the cinema has probably been the most
            important source of inspiration for Jarkko. "I just love everything
            about films. The combination of literature, theater, visual arts and
            music has always been fascinating for me. As a child E.T. blew my
            mind, as did Amadeus. While still living in Helsinki, I was an
            active guest of the Cinema Orion, that showed a wide variety of art
            films. I still remember a series of early work of Ingmar Bergman,
            having that kind of a theater in my home town was luxurious. I also
            love to read, everything from fiction and poetry to scientific
            books. And naturally there is a direct connection to music: reading
            Russian literature helps you deepen your understanding of the
            Russian music, as an example."
          </p>
          <p>
            At the end of the day there are also other interests. "I'm a huge
            fan of high-quality comedy, standup, series or movies - anything.
            I'll never forget the live shows of e.g. Eddie Izzard or Ricky
            Gervais, it can be an unforgettable experience, very much like a
            good concert. I also love sports, both watching it and doing it
            myself. A crossfit session or a bike ride rearranges something in my
            head, it is so much easier to continue working after that. Also
            cooking a dinner for my friends brings me joy, I'm continuously
            looking for new influences and recipes."
          </p>
          {close}
        </article>

        <article
          id="partners"
          className={`${this.props.article === 'partners' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Partners</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
            I got to know the members of the Fauré Quartet on a music festival
            in Finland many years ago. Since I have had the joy of working with
            them many times over the years, and I'm happy that I can call them
            friends.
            <br />
            <a
              href="http://www.faurequartett.de/en/"
              target="_blank"
              rel="noopener noreferrer"
            >
              http: //www.faurequartett.de/en/
            </a>
          </p>
          <p>
            {' '}
            The Fauré Quartet invited both me and the clarinettist Matthias
            Schorn on a festival, that's how I got to know this unique musician.
            Not only an international soloist and the principal clarinettist of
            the Vienna Philharmonic orchestra, but also always looking for new
            ways of expression and bringing together musicians with various
            backrounds.
            <br />
            <a
              href="https://www.youtube.com/watch?v=XtNW6CMMo_8"
              target="_blank"
            >
              Matthias Schorn spielt "Tango Clubb" von Jarkko Riihimäki
              (YouTube)
            </a>
            <br />
            <a
              href="https://matthias-schorn.at/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https: //matthias-schorn.at/
            </a>
          </p>
          <p>
            A festival on which I got to know many great colleaques is the
            "Festspiele Mecklenburg-Vorpommern". The contact started many years
            ago, and since then I have been collaborating with the warmhearted
            and ambitious co-workers, writing arrangements and playing concerts.
            <br />
            <a
              href="https://festspiele-mv.de/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https: //festspiele-mv.de/
            </a>
          </p>
          <p>
            It was also there where I got to know the very versatile
            clarinettist David Orlowsky. Back in the day I would write
            arrangements for his trio, but later also for his solo projects.
            <br />
            <a
              href="https://www.davidorlowsky.com/en"
              target="_blank"
              rel="noopener noreferrer"
            >
              https: //www.davidorlowsky.com/en
            </a>
          </p>
          <p>
            I am thankful of beeing a part of the initiative "Rhapsody in
            School". The idea is to bring (classical) music to schools, tell
            about the own backround and answer questions. I also played for a
            group of immigrant kids, which was a breathtaking experience.
            <br />
            <a
              href="https://www.rhapsody-in-school.de/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https: //www.rhapsody-in-school.de/
            </a>
          </p>

          <h3>Other colleagues and partners</h3>
          <p>
            <a
              href="https://www.simone-kermes.de/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https: //www.simone-kermes.de
            </a>
            <br />
            <a
              href="http://www.danielroehn.com/golden-violin/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              http: //www.danielroehn.com/golden-violin/
            </a>
            <br />
            <a
              href="https://www.facebook.com/MoritzvonOswald/?tn-str=k*F"
              target="_blank"
              rel="noopener noreferrer"
            >
              https: //www.facebook.com/MoritzvonOswald/?tn-str=k*F
            </a>
            <br />
            <a
              href="https://www.rajaton.net/?lang=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              https: //www.rajaton.net/?lang=en
            </a>
            <br />
            <a
              href="http://www.clubforfive.fi/home"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              http: //www.clubforfive.fi/home
            </a>
            <br />
            <a
              href="https://www.wecker.de/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              https: //www.wecker.de/
            </a>
            <br />
            <a
              href="https://www.symphonic-mob.de/content/e7692/e582/e440/index_ger.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              https:
            //www.symphonic-mob.de/content/e7692/e582/e440/index_ger.html
            </a>
          </p>

          <p className="logos">
            <a
              href="https://www.sonyclassical.com/home"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={sonyclassical}
                className="logo"
                alt="Sony Classical"
                title="Sony Classical"
              />{' '}
            </a>
            <a
              href="https://www.edel.com/music/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={edel} className="logo" alt="Edel" title="Edel" />
            </a>
            <a
              href="https://www.arte.tv/en/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={arte} className="logo" alt="ARTE" title="ARTE" />
            </a>
            <a
              href="https://www.zdf.de/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={zdf} className="logo" alt="ZDF" title="ZDF" />
            </a>
            <a
              href="http://www.staatskapelle-dresden.de/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={ssd}
                className="logo"
                alt="Staatskapelle Dresden"
                title="Staatskapelle Dresden"
              />
            </a>
            <a
              href="https://www.konzerthaus.de/en/konzerthausorchester-berlin"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={khb}
                className="logo"
                alt="Konzerthaus Berlin"
                title="Konzerthaus Berlin"
              />
            </a>
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <p>
            <strong>The form is currently offline</strong> while we work out
            some things.
          </p>
          <p> Meanwhile please contact me through social media!</p>
          <ul className="icons">
            <li>
              <a
                href="https://www.facebook.com/JarkkoRiihimakiMusician/"
                className="icon fa-facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/jarkkoriihimakifilmorchestra/"
                className="icon fa-facebook-square"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/user/wwwjarkkofi/featured?view_as=subscriber"
                className="icon fa-youtube"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/jarkkoriihimaki/?hl=fi"
                className="icon fa-instagram"
              >
                <span
                  className="label"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </span>
              </a>
            </li>
          </ul>
          <form method="post" action="#" onSubmit={this.handleSubmit}>
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                value={this.state.name}
                disabled={this.state.formOffline}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                name="email"
                value={this.state.email}
                disabled={this.state.formOffline}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea
                name="msg"
                rows="6"
                value={this.state.msg}
                onChange={this.handleInputChange}
                disabled={this.state.formOffline}
              />
            </div>
            <ul className="actions">
              <li>
                <input
                  type="submit"
                  value="Send Message"
                  className="special"
                  disabled={this.state.formOffline}
                />
              </li>
              <li>
                <input
                  type="reset"
                  value="Reset"
                  disabled={this.state.formOffline}
                  onClick={this.handleInputReset}
                />
              </li>
            </ul>
          </form>
          {close}
        </article>
      </div >
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
