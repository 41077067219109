import React from 'react'
import PropTypes from 'prop-types'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="content">
      <div className="inner">
        <h1>Jarkko Riihim&auml;ki</h1>
        <p>Pianist / Music Arranger / Composer</p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <a
            href="javascript:;"
            onClick={() => {
              props.onOpenArticle('news')
            }}
          >
            News
          </a>
        </li>
        <li>
          <a
            href="javascript:;"
            onClick={() => {
              props.onOpenArticle('bio')
            }}
          >
            Bio
          </a>
        </li>
        <li>
          <a
            href="javascript:;"
            onClick={() => {
              props.onOpenArticle('partners')
            }}
          >
            Partners
          </a>
        </li>
        <li>
          <a
            href="javascript:;"
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </a>
        </li>
      </ul>
    </nav>
    <ul className="icons">
      <li><a href="https://www.facebook.com/JarkkoRiihimakiMusician/" className="icon fa-facebook" target="_blank" rel="noopener noreferrer"><span className="label">Facebook</span></a></li>
      <li><a href="https://www.facebook.com/jarkkoriihimakifilmorchestra/" className="icon fa-facebook-square" target="_blank" rel="noopener noreferrer"><span className="label">Twitter</span></a></li>
      <li><a href="https://www.youtube.com/user/wwwjarkkofi/featured?view_as=subscriber" className="icon fa-youtube" target="_blank" rel="noopener noreferrer"><span className="label">Instagram</span></a></li>
      <li><a href="https://www.instagram.com/jarkkoriihimaki/?hl=fi" className="icon fa-instagram" target="_blank" rel="noopener noreferrer"><span className="label">Instagram</span></a></li>
    </ul>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
